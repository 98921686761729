import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import { motion, AnimatePresence, useViewportScroll, useTransform, transform } from "framer-motion"
import { Layout } from '../components'
import Home from '../templates/home'
import { parseACF } from '../utils'
import { useWindowScroll, useWindowSize, useMount } from 'react-use';
import { isClient, useBreakpoint } from '../styles/utils';

export const triggerPoint = 0.8;
export const opacityOffset = 0.1;

let gradientInterval;

let SmoothScroll;

if (isClient()) {
    SmoothScroll = require('smooth-scroll')
}

const Index = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [gradient, updateGradient] = useState(0) 
    const [homeVisible, setHomeVisible] = useState(false)
    const { y } = useWindowScroll();
    const { width, height } = useWindowSize();
    const { scrollYProgress } = useViewportScroll()
    const isTablet = useBreakpoint('tablet');
    
    const scale = useTransform(scrollYProgress, [0, triggerPoint], [0.0085, isTablet ? 3.5 : 2.5], {ease: t => t*t })
    const opacity = useTransform(scrollYProgress, [(triggerPoint - 0.165) , (triggerPoint)], [0, 1])

    useMount(() => {
        const scrollPoint = ((isTablet ? height * 32 : height * 28) * triggerPoint);

        const scrollOptions = {
            speed: 300, 
            easing: 'easeInQuad',
        }
        
        var scroll = new SmoothScroll()        
        scroll.animateScroll(scrollPoint, '', scrollOptions)
    })

    useEffect(() => {
        const scrollPoint = (((height * 28) * triggerPoint) - 100);

        if (y >= scrollPoint && !homeVisible) {
            setHomeVisible(true)
        }

        if (y <= scrollPoint && homeVisible) {
            setHomeVisible(false)
        }
    }, [y])

    return (
		<Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Circle
                    homeVisible={homeVisible}
                    style={{ 
                        scale
                    }} 
                >

                </Circle>

                <Gradient
                    homeVisible={homeVisible}
                    style={{ 
                        opacity
                    }}
                >
                    <PageWrapper>
                        <Home/>
                    </PageWrapper>
                </Gradient>
            </Wrapper>
		</Layout>	
	)
}


const gradientAnimation = keyframes`
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
`;


const gradient = css`
    background: linear-gradient(360deg, #020204, #994F10, #DDBAEA, #4FBFFF, #0E58BB);
    background-size: 100vw 600vh;

    ${props => {
        if (props.homeVisible) return css`
            animation: ${gradientAnimation} 60s linear infinite;
        `
    }}
`

const Wrapper = styled.div`
    height: 3000vh;
`

const Circle = styled(motion.div)`
    height: 70vw;
    width: 70vw;
    position: fixed;
    top: calc(50% - 35vw);
    left: calc(50% - 35vw);
    background: white;
    border-radius: 50%;
    background: #0E58BB;
`

const Gradient = styled(motion.div)`
    transition: background 5s ease;
    transform: translate3d(0,0,0);
    will-change: background;
    /* will-change: opacity; */
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${gradient};
`

const PageWrapper = styled(motion.div)`

`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`


export default Index