import React, { useState, useEffect, } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence, useViewportScroll, useTransform } from "framer-motion"
import { Gradient } from 'react-gradient';

import { Layout } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { white, grey} from '../styles/colors'
import { parseACF } from '../utils'
import { useWindowSize, useMeasure } from 'react-use';
import { media, useBreakpoint, isClient } from '../styles/utils';
import { triggerPoint } from '../pages';

let SmoothScroll;

if (isClient()) {
    SmoothScroll = require('smooth-scroll')
}

const Home = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [footerTextIndex, updateFooterTextIndex] = useState(0) 
    const {scrollYProgress} = useViewportScroll()
    const {width, height} = useWindowSize();

    const isTablet = useBreakpoint('tablet');

    const scrollToTop = () => {
        const scrollOptions = {
            speed: 50, 
            easing: 'easeInOutCubic',
        }
        
        var scroll = new SmoothScroll()        
        scroll.animateScroll(0, '', scrollOptions)
	}

    useEffect(() => {
        // Cycle through footer text

        const interval = setInterval(() => {
            updateFooterTextIndex(footerTextIndex + 1)
        }, 5000);

        return () => clearInterval(interval);
    })

    const renderHeader = () => {
        const bottomPadding = isTablet ? 175 : 235;
        const translateY = useTransform(scrollYProgress, [triggerPoint, 1], [0, (height - bottomPadding)])
        const headerElements = [Us, From, Space]

        const elements = headerElements.map((Component, i) => {
            const startPoint = (triggerPoint + 0.025);
            const logoTriggger = startPoint + (((1 - startPoint) / 3) * (i + 1)) - 0.1;
            const opacity = useTransform(scrollYProgress, [logoTriggger, 1], [0, 1])
            
            return <Component
                style={{opacity}}
            />
        })

        return (
            <Header
                onClick={scrollYProgress.get() == 1 && scrollToTop}
                style={{
                    translateY,
                    scale: isTablet ? 0.8 : 1
                }}
            >
                <UFS/>
                {elements}
            </Header>
        )
    }
    
    const renderIntro = () => {
        return (
            <Intro>
                <Subheading>{data.about_heading}</Subheading>
                <Text
                    dangerouslySetInnerHTML={{__html: data.about_text}}
                />
            </Intro>
        )
    }

    const renderColumnSections = (columnData) => {
        if(!columnData) return

        const items = columnData.map((item, i) => {
            return (
                <Section key={i}>
                    {item.heading && (
                        <Subheading>{item.heading}</Subheading>
                    )}

                    <Text
                        dangerouslySetInnerHTML={{__html: item.text}}
                    />
                </Section>
            )
        })
        return (
            <Sections>{items}</Sections>
        )
    }

    const renderColumns = () => {
        const items = [data.column_1, data.column_2, data.column_3].map((item, i) => {
            return (
                <Column key={i}>
                    {renderColumnSections(item)}
                </Column>
            )
        })

        return (
            <Columns>{items}</Columns>
        )
    }

    const renderFooterText = () => {
        const idx = footerTextIndex % data.footer_text_items.length
        const phrase = data.footer_text_items[idx]

        return (
            <AnimatePresence
                exitBeforeEnter
            >
                <FooterText
                    key={idx}
                    {...animatedFooterText}
                >
                    <Text>
                        {phrase.item}
                    </Text>
                </FooterText>
            </AnimatePresence>       
        )
    }

    const [containerRef, { height: containerHeight }] = useMeasure();
    const translateY = useTransform(scrollYProgress, [triggerPoint, 1], [0, -((containerHeight + 340) - height)])
    
	return (
        <Wrapper>
            {renderHeader()}

            <Container
                ref={containerRef}
                style={{
                    translateY: (containerHeight + 300) > height && translateY
                }}
            >
                {renderIntro()}
                {renderColumns()}
                {renderFooterText()}

                {data.instagram_username && (
                    <Instagram
                        target={'_blank'}
                        href={`https://instagram.com/${data.instagram_username}`}
                    />
                )}
            </Container>
        </Wrapper>
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Text = styled.div``

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    min-height: 100vh;
    position: relative;
    z-index: 2;

    ${Subheading} {
        color: white;
        text-transform: uppercase;
        font-style: italic;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.025em;
        margin-bottom: 14px;
        opacity: 0.8;   

        ${media.tablet`
            font-size: 17px;
            line-height: 22px;
        `}
    }

    ${Text} {
        &, p, a {
            color: ${white};
            font-size: 30px;
            line-height: 39px;
            letter-spacing: -0.025em;

            ${media.tablet`
                font-size: 20px;
                line-height: 26px;
            `}
        }
        
        a {
            text-decoration: underline;
        }
    }   


    ${media.tablet`
        min-height: none;
    `}
`

const Container = styled(motion.div)`
	${container}
	${padding}
    display: flex;
    flex-direction: column;
    padding-top: 43px;
    padding-left: 196px;
    padding-bottom: 300px;

    ${media.tablet`
        padding-left: 67px;
        padding-top: 32px;
        max-width: 100vw;
    `}
`

// Heading

const UFS = styled(motion.div)`
    background-image: url(${require('../assets/images/header/UFS.svg')});
    width: 21px;
    height: 142px;
`

const Us = styled(motion.div)`
    background-image: url(${require('../assets/images/header/US.svg')});
    width: 50px;
    top: 35px;
`

const From = styled(motion.div)`
    background-image: url(${require('../assets/images/header/FROM.svg')});
    width: 76px;
    top: 73px;
`

const Space = styled(motion.div)`
    background-image: url(${require('../assets/images/header/SPACE.svg')});
    width: 103px;
    top: 111px;
`

const Header = styled(motion.div)`
    position: fixed;
    left: 38px;
    top: 48px;
    transform-origin: top left;
    cursor: pointer;
    z-index: 20;

    ${UFS} {
        padding-right: 24px;
        transform: translateX(-12px);
    }

    ${UFS},
    ${Us},
    ${From},
    ${Space} {
        ${bgIcon};
    }

    ${Us},
    ${From},
    ${Space} {
        left: 29px;
        height: 30px;
        position: absolute;
    }

    ${media.tablet`
        left: 24px;
        top: 37px;
        bottom: 20px;
    `}
`

// Intro

const Intro = styled.div`
    margin-bottom: 30px;
`

// Columns

const Column = styled.div`
    flex: 1 0 33.33%;

    &:not(:last-child) {
        paddin-right: 100px;
        box-sizing: border-box;
    }

    ${media.tablet`
        flex: 1 0 auto;
        padding-right: 0 !important;
    `}
`

const Columns = styled.div`
    display: flex;

    ${media.tablet`
        flex-direction: column;
    `}
`

// Column Sections

const Section = styled.div``
const Sections = styled.div``


const FooterText = styled(motion.div)`
    position: absolute;
    bottom: 40px;
    left: calc(33.3% + 106px);

    ${media.tablet`
        display: none;
    `}
`

const animatedFooterText = {
	initial: {
        opacity: 0
	},
	animate: {
        opacity: 1,
        transition: {
            duration: 1
        }
	},
	exit: {
        opacity: 0,
        transition: {
            duration: 1
        }
	},
}

// Instagram

const Instagram = styled.a`
    background-image: url(${require('../assets/images/icon-instagram.svg')});
    height: 26px;
    width: 26px;
    ${bgIcon};
    ${hoverState};
    cursor: pointer;

    position: absolute;
    right: 38px;
    bottom: 35px;

    ${media.tablet`
        bottom: 17px;
        right: 27px;
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Home